import SocialProtectionComponent from 'components/newDesign/SocialProtectionComponent';
import React, { Suspense, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCategoryListQuery } from 'store/hooks/services';
import MobileSlider from 'components/newDesign/MobileSlider';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function ServicesSection1({
  categoryId,
  setCategoryId,
}: {
  categoryId: string | null;
  setCategoryId: (val: string) => void;
}) {
  const { data: categories } = useGetCategoryListQuery();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 660px)');
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPreviousSlide = () => {
    if (categories?.length) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + categories.length) % categories.length
      );
    }
  };

  const goToNextSlide = () => {
    if (categories?.length) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }
  };

  return (
    <>
      {/* services ministry section */}
      <div className="tw-flex tw-justify-center">
        <div className="tw-w-full tw-mx-auto tw-max-w-[1282px] tw-flex tw-justify-center tw-px-5 md:tw-px-0">
          <div className="tw-w-[90%] tw-mx-auto md:tw-mx-0 md:tw-w-full tw-rounded-[24px] tw-bg-gradient3 tw-p-5">
            {!isSmallDevice ? (
              <div className="tw-mr-0 tw-flex tw-gap-6 md:tw-mr-[38px] flex-col tw-flex-row">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {categories?.map((d: any, index: number) => (
                    <SocialProtectionComponent
                      socialTitle={d.value}
                      key={'cardbox' + index}
                      handleClick={() => setCategoryId(d.id)}
                      socialImage={
                        (index + 1) % 2 === 0
                          ? '/images/img_simplification.svg'
                          : (index + 1) % 3 === 0
                          ? '/images/img_simplification_white_a700_01.svg'
                          : undefined
                      }
                      className={categoryId === d.id ? 'tw-bg-[#48B7F4]' : ''}
                    />
                  ))}
                </Suspense>
              </div>
            ) : (
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                modules={[Navigation]}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                className="!tw-z-0"
              >
                {categories?.map((d: any, index: number) => (
                  <SwiperSlide key={'cardbox' + index}>
                    <SocialProtectionComponent
                      socialTitle={d.value}
                      handleClick={() => setCategoryId(d.id)}
                      socialImage={
                        (index + 1) % 2 === 0
                          ? '/images/img_simplification.svg'
                          : (index + 1) % 3 === 0
                          ? '/images/img_simplification_white_a700_01.svg'
                          : undefined
                      }
                      className={
                        categoryId === d.id
                          ? 'tw-bg-[#48B7F4] tw-w-[90%] tw-mx-auto tw-py-3'
                          : 'tw-w-[90%] tw-mx-auto tw-py-3'
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
