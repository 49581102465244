import React from 'react';

const ThirdText = () => {
  return (
    <>
      <div>
        <p>
          Для того чтобы войти в свою учетную запись, необходимо пройти
          процедуру авторизации. Эта процедура одинаковая как для физических,
          так и для юридических лиц.
        </p>
        <p className="tw-mt-[10px]">
          Шаги для авторизации физического или юридического лица:
        </p>
        <ul className="tw-list-decimal tw-pl-[30px]">
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">
              Логин (номер телефона пользователя):
            </span>{' '}
            <br />
            Введите номер мобильного телефона, который вы использовали при
            регистрации вашей учетной записи.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Пароль (Password):</span> <br />
            Введите ваш пароль, который был установлен во время регистрации.
            Убедитесь, что вы вводите его правильно, включая все символы,
            заглавные и строчные буквы.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Отправить код:</span> <br />
            Нажмите на кнопку "Отправить код". После этого вам будет отправлен
            код подтверждения на номер телефона, который вы указали при
            регистрации.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Код:</span> <br />
            Введите код, который вы получили на указанный вами контактный номер.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Вход:</span> <br />
            После того как вы введете правильный код подтверждения, нажмите
            кнопку "Вход", чтобы завершить процесс авторизации и войти в свою
            учетную запись.
          </li>
        </ul>
        
      </div>
    </>
  );
};

export default ThirdText;
