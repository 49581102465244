import React from 'react';

interface Props {
  className?: string;
  socialImage?: string;
  socialTitle?: React.ReactNode;
  handleClick: () => void;
}

export default function SocialProtectionProfile({
  socialImage = 'images/img_simplification_deep_purple_200.svg',
  socialTitle = 'Социальная защита',
  handleClick,
  ...props
}: Props) {
  return (
    <div
      onClick={handleClick}
      {...props}
      className={`${props.className} tw-flex tw-flex-col tw-items-center tw-w-full tw-gap-4 tw-px-[16px] tw-py-6 tw-bg-gray-50 tw-rounded-[12px] hover:tw-bg-[#C8E4FF] hover:tw-cursor-pointer tw-transition-colors tw-ease-linear tw-duration-150`}
    >
      <img
        src={socialImage}
        alt="Социальная"
        className="tw-h-[56px] tw-w-[38%] tw-object-contain"
      />
      <p className="tw-mb-5 tw-overflow-hidden tw-overflow-ellipsis tw-text-[15px] tw-text-center tw-font-roboto tw-font-medium tw-text-[#66727F]">
        {socialTitle}
      </p>
    </div>
  );
}
