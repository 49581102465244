import React from 'react';

type InputProps = Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'prefix' | 'size'
> &
  Partial<{
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
  }>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = '',
      name = '',
      placeholder = '',
      type = 'text',
      label = '',
      prefix,
      suffix,
      onChange,

      ...restProps
    },
    ref
  ) => {
    return (
      <label className={`${className} undefined   `}>
        {!!label && label}
        {!!prefix && prefix}
        <input
          ref={ref}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          {...restProps}
          className="tw-outline-none tw-w-full md:tw-w-[280px]"
        />
        {!!suffix && suffix}
      </label>
    );
  }
);

export { Input };
