import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import { Action, configureStore } from '@reduxjs/toolkit';
// import { api } from './api';
import { createApi } from '@reduxjs/toolkit/query/react';
import credentialsSlice from './slices/credentials';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { IQueryArgs } from './api';
import axios from 'shared/utils/axios';
import { AxiosError } from 'axios';


const baseQuery =
  (): BaseQueryFn<IQueryArgs> =>
  async ({ url, method = 'GET', data, params }) => {
    try {
      const response = await axios({ url, method, data, params });
      return {
        data: response.data,
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.code,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const api = createApi({
  reducerPath: 'mainApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({}),
  tagTypes: [],
});


const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    credentials: credentialsSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;