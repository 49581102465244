import React, { useState } from 'react';

interface Props {
  className?: string;
  titleText?: React.ReactNode;
  imgSrc?: string;
  children?: React.ReactNode;
  iconRotate?: string;
}

export default function DigitalSignatureKeyInfo({
  titleText = 'Как получить ключ электронной цифровой подписи?',
  imgSrc = 'images/img_arrow_right.svg',
  iconRotate = 'tw-rotate-90',
  children = (
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s, when an unknown printer took a galley of type and scrambled it to
      make a type specimen book.
    </p>
  ),
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div
      {...props}
      className={`tw-flex tw-flex-col tw-justify-between tw-items-center tw-w-full tw-px-8 tw-py-5 tw-bg-[#F7F8F9] tw-rounded-[12px] ${props.className} tw-cursor-pointer`}
    >
      <div
        className="tw-flex tw-items-center tw-justify-between tw-w-full cursor-pointer"
        onClick={handleToggle}
      >
        {titleText}
        <img
          src={imgSrc}
          alt="Image"
          className={`tw-h-[32px] transition-transform ${
            isOpen ? iconRotate : ''
          }`}
        />
      </div>
      {isOpen && (
        <div className="tw-mt-4 tw-w-full tw-px-2 tw-py-3 tw-bg-transparent tw-max-h-[250px] tw-overflow-y-auto">
          {children}
        </div>
      )}
    </div>
  );
}
