import React from 'react';

interface Props {
  className?: string;
  documentTitle?: React.ReactNode;
  handleClick: () => void;
}

export default function DocumentSearchIconWithTitle({
  documentTitle = 'Справка об отсутствии налоговой задолженности',
  handleClick,
  ...props
}: Props) {
  return (
    <div
      {...props}
      onClick={handleClick}
      className={`${props.className} tw-flex tw-justify-center tw-items-center tw-w-full tw-gap-4 tw-p-4 tw-bg-gray-50 tw-rounded-[12px] hover:tw-bg-[#C8E4FF] hover:tw-drop-shadow-lg tw-transition-all tw-ease-linear tw-duration-150 tw-cursor-pointer`}
    >
      <button className="tw-flex tw-h-[56px] tw-w-[56px] tw-items-center tw-justify-center tw-rounded-lg tw-bg-[#007B9E4D] tw-px-3">
        <img src="images/img_iconoir_page_search_cyan_800.svg" />
      </button>
      <p className="tw-w-[78%] tw-overflow-hidden tw-overflow-ellipsis tw-text-[14px] tw-font-roboto tw-font-medium tw-leading-[18px] tw-text-[#66727F]">
        {documentTitle}
      </p>
    </div>
  );
}
