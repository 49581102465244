import { Outlet, RouteObject } from 'react-router-dom';
import PrivateLayout from './layouts/private';
import LoginPage from './routes/public/login';
import PublicLayout from './layouts/public';
import HomePage from './routes/public/home';
import ApplicationResidentPage from './routes/private/application-resident';
import { ApplicationResidentCreatePage } from './routes/private/application-resident/create';
import { ApplicationResidentUpdatePage } from './routes/private/application-resident/update';
import Profile from './routes/private/profile';
import ServiceDetailPage from './routes/public/service-details';
import RegistrationPage from './routes/public/registration';
import Services from './routes/private/profile/services';
import Account from './routes/private/profile/account';
import Organization from './routes/private/profile/organization';
import Requisites from './routes/private/profile/requisites';
import Documents from './routes/private/profile/documents';
import EgovServices from './routes/private/services';
import ServiceDetails from './routes/private/services/details';
import ConfirmationDetails from './routes/private/confirmation';
import PublicContentLayout from './layouts/public/PublicContentLayout';
import Support from './routes/private/profile/support';
import ServiceCreateApplication from './routes/private/services/application/create';
import ServiceApplicationsPage from 'routes/private/services/application';
import MyServices from 'routes/private/my-services';
import Reestr from 'routes/private/reestr';
import Receipt from 'routes/public/reciept';
import NotFound from 'routes/public/not-found';
import ForgetPassword from 'routes/public/forget-password';
import AdminAuth from 'auth/RoleAuth';
import User from './routes/private/reestr/user';
import PublicServices from 'routes/public/services/Services';
import Instruction from 'routes/public/instruction';

const routes: RouteObject[] = [
  {
    path: 'services',
    element: (
      <PrivateLayout>
        <Outlet />
      </PrivateLayout>
    ),
    children: [
      {
        path: '',
        element: <EgovServices />,
      },

      {
        path: 'profile/:orgId',
        element: <PublicServices />,
      },
      {
        path: 'details/:serviceId',
        element: <ServiceDetails />,
      },
      {
        path: 'details/:serviceId/application',
        element: <ServiceApplicationsPage />,
      },
      {
        path: 'details/:serviceId/application/create',
        element: <ServiceCreateApplication />,
      },
      {
        path: 'details/:serviceId/application/show/:applicationId',
        element: <ServiceCreateApplication />,
      },
      { path: 'instruction', element: <Instruction /> },
    ],
  },
  // {
  //   path: 'application-resident',
  //   element: (
  //     <PrivateLayout>
  //       <ApplicationResidentPage />
  //     </PrivateLayout>
  //   ),
  // },
  // {
  //   path: 'application-resident/create',
  //   element: (
  //     <PrivateLayout>
  //       <ApplicationResidentCreatePage />
  //     </PrivateLayout>
  //   ),
  // },
  // {
  //   path: 'application-resident/update/:id',
  //   element: (
  //     <PrivateLayout>
  //       <ApplicationResidentUpdatePage />
  //     </PrivateLayout>
  //   ),
  // },
  {
    path: 'my-services',
    element: (
      <PrivateLayout>
        <MyServices />
      </PrivateLayout>
    ),
  },
  {
    path: 'receipt/:id',
    element: <Receipt />,
  },
  {
    path: 'profile',
    element: (
      <PrivateLayout>
        <Profile />
      </PrivateLayout>
    ),
    children: [
      { path: 'services', element: <Services /> },
      { path: 'account', element: <Account /> },
      { path: 'requisites', element: <Requisites /> },
      { path: 'documents', element: <Documents /> },
      { path: 'support', element: <Support /> },
      { path: 'organization', element: <Organization /> },
    ],
  },
  {
    path: '/',
    element: <PublicContentLayout />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      { path: '/instruction', element: <Instruction /> },
      {
        path: 'service-details/:serviceId',
        element: <ServiceDetails />,
      },
      {
        path: `confirmation/:applicationId`,
        element: <ConfirmationDetails />,
      },
      {
        path: 'services/:orgId',
        element: <PublicServices />,
      },
    ],
  },
  {
    path: 'admin',
    element: (
      <PrivateLayout>
        <AdminAuth>
          <Reestr />
        </AdminAuth>
      </PrivateLayout>
    ),
  },
  {
    path: 'admin/:id',
    element: (
      <PrivateLayout>
        <AdminAuth>
          <User />
        </AdminAuth>
      </PrivateLayout>
    ),
  },
  {
    path: 'auth',
    element: <PublicLayout />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'registration',
        element: <RegistrationPage />,
      },
      {
        path: 'forget-password',
        element: <ForgetPassword />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
