import React from 'react';

const SeventhText = () => {
  return (
    <div>
      <p>
        После того как вы отправили заявку на получение услуги, вам будет
        предложено оплатить соответствующую услугу. Процесс оплаты включает
        несколько шагов, которые зависят от выбранного вами способа оплаты.
      </p>
      <p className="tw-mt-[10px]">Шаги для оплаты услуги:</p>
      <ul className="tw-list-decimal tw-pl-[30px]">
        <li className="tw-mt-[10px]">
          Получение реквизитов для оплаты: После отправки вашей заявки на номер
          мобильного телефона, указанный при регистрации, придет смс-сообщение с
          реквизитами для оплаты и общей суммой для оплаты, включая:
          <ul className=" tw-list-disc tw-pl-[30px]">
            <li>
              <span className="tw-font-bold">Счет оплаты:</span> 0000000001
            </li>
            <li>
              <span className="tw-font-bold">Цена обработки заявления:</span>{' '}
              сумма в сомони.
            </li>
            <li>
              <span className="tw-font-bold">Цена услуги:</span> сумма в сомони.
            </li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          Выбор способа оплаты: Вы можете оплатить услугу через один из
          следующих электронных кошельков:
          <ul className="tw-list-disc tw-pl-[30px]">
            <li className="tw-font-bold tw-mt-[10px]">Alif Mobi</li>
            <li className="tw-font-bold tw-mt-[10px]">Spitamen Pay</li>
            <li className="tw-font-bold tw-mt-[10px]">Амонат мобайл</li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          Пример оплаты через Alif Mobi: Для оплаты через Alif Mobi выполните
          следующие шаги:
          <ul className="tw-list-disc tw-pl-[30px]">
            <li className="tw-mt-[10px]">
              Откройте приложение{' '}
              <span className="tw-font-bold">Alif Mobi</span>{' '}
            </li>
            <li className="tw-mt-[10px]">
              Перейдите в раздел{' '}
              <span className="tw-font-bold">"Все услуги".</span>{' '}
            </li>
            <li className="tw-mt-[10px]">
              В списке услуг выберите{' '}
              <span className="tw-font-bold">"Государственные услуги".</span>{' '}
            </li>
            <li className="tw-mt-[10px]">
              В открывшемся списке найдите и выберите{' '}
              <span className="tw-font-bold">
                "Услуги Министерства Финансов РТ".
              </span>{' '}
            </li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          Заполнение полей для оплаты: На следующем экране вам нужно будет
          ввести следующие данные:
          <ul className="tw-list-disc tw-pl-[30px]">
            <li>
              <span className="tw-font-bold tw-mt-[10px]">Номер счета:</span>{' '}
              Введите номер счета, указанный в смс-сообщении (например,
              0000000001).
            </li>
            <li>
              <span className="tw-font-bold tw-mt-[10px]">Номер счета:</span>{' '}
              Введите номер счета, указанный в смс-сообщении (например,
              0000000001).
            </li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold"> Подтверждение и оплата:</span> После
          того как вы заполнили все необходимые поля, нажмите кнопку{' '}
          <span className="tw-font-bold">"Оплатить".</span>{' '}
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">Получение квитанции:</span> После
          успешной оплаты вам на номер телефона поступит смс-сообщение со
          ссылкой на <span className="tw-font-bold">квитанцию об оплате. </span>{' '}
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">Статус заявки:</span> Как только оплата
          будет произведена и подтверждена, статус вашей заявки изменится с{' '}
          <span className="tw-font-bold">"Не оплачено"</span> на <span className='tw-font-bold'> "Оплачено"</span>. Это
          означает, что заявка теперь будет рассмотрена и выполнена
          соответствующими уполномоченными органами.
        </li>
      </ul>
    </div>
  );
};

export default SeventhText;
