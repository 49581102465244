import { Button } from '@mui/material';
import { ActionsGroup } from 'components/forms/ActionsGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useSendMessageToUserForAuthorizeMutation,
  useUserProfileUserAuthorizeMutation,
} from 'store/hooks/userprofile';

export const UserAction = (props: any) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [call] = useUserProfileUserAuthorizeMutation();
  const [sendMessageToUserForAuthorize] =
    useSendMessageToUserForAuthorizeMutation();

  return (
    <ActionsGroup onBack={() => navigate(-1)}>
      <Button
        onClick={async () => {
          await sendMessageToUserForAuthorize({
            id: id,
          });
          toast.success(
            'Клиенту будет отправлено смс для добавление необходимых документов'
          );
          props.refetch();
        }}
      >
        Отвергнуть
      </Button>
      <Button
        onClick={async () => {
          await call({
            id: id,
            authorized: true,
          });
          toast.success('Пользователь успешно аутентифицирован');
          props.refetch();
        }}
      >
        Аутентифицировать
      </Button>

      {props.disabled ? (
        <Button
          onClick={async () => {
            props.save();
          }}
        >
          Сохранить
        </Button>
      ) : (
        <Button
          onClick={async () => {
            props.editable();
          }}
        >
          Изменить
        </Button>
      )}
    </ActionsGroup>
  );
};
