import { Box, Typography, useMediaQuery } from '@mui/material';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { boxSx } from './styles';
import { menuItem } from './constants';
import { useGetUserprofileDetailsQuery } from 'store/hooks/userprofile';

const Profile = () => {
  const navigate = useNavigate();
  const { pathname: route } = useLocation();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const { data: profileData } = useGetUserprofileDetailsQuery();

  const clickHandler = (path: string) => {
    navigate(path);
  };
  return (
    <Box className="lg:tw-grid lg:tw-grid-cols-4" sx={{ pt: 4 }}>
      <Box className="tw-col-span-1">
        <Typography
          variant="h3"
          sx={{
            mb: 7,
            pl: isSmallDevice ? 0 : '5rem',
          }}
        >
          {/* Профиль */}
        </Typography>
        <MenuList
          className="sm:tw-hidden "
          sx={{
            display: isSmallDevice ? 'none' : 'flex',
            flexDirection: 'column',
            gap: 1,
            '& .MuiButtonBase-root': {
              borderRadius: 3,
            },
          }}
        >
          {menuItem
            .filter((item) => {
              if (profileData?.userType != 2) {
                return item.path !== 'organization';
              }
              return item;
            })
            .map(({ id, title, Icon, path, pathName }) => (
              <MenuItem
                key={id}
                onClick={() => clickHandler(path)}
                selected={pathName === route}
              >
                <ListItemIcon>
                  <Icon fontSize="large" />
                </ListItemIcon>
                <Typography fontSize="18px" variant="inherit">
                  {title}
                </Typography>
              </MenuItem>
            ))}
        </MenuList>
      </Box>
      <Box
        className="tw-col-span-3 lg:tw-pl-4 "
        sx={{
          pt: isSmallDevice ? 0 : 8.5,
        }}
      >
        <Box
          sx={{
            borderRadius: '20px',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.24)',
            background: 'white',
            padding: isSmallDevice ? '1.5rem' : '4rem',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
