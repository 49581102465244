import {
  Box,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { logout } from 'store/slices/credentials/actions';
import { mainStyles } from './style';
import Logo from '../../components/ui/Logo';
import { useDispatch } from 'store';
import { getTokenFromStorage } from '../../shared/utils/getTokenFromStorage';
import SnowFlake from '../../components/ui/SnowFlake';
import './index.scss';
import './publicContentLayout.scss';
import {
  useFetchHotLineEmailQuery,
  useFetchHotLinePhoneQuery,
} from 'store/hooks/general';
import { supportStyles } from 'layouts/private/styles';
import MainMenu from 'components/ui/MainMenu';
import { Close } from '@mui/icons-material';
import Footer from 'components/newDesign/Footer';

interface IPublicContentLayout {
  children?: ReactNode;
}

const PublicContentLayout: FC<IPublicContentLayout> = ({ children }) => {
  const isAnimationAllowed = parseInt(
    process.env.REACT_APP_SEASON_ANIMATION || ''
  );

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openBurger, setOpenBurger] = useState(false);

  const burgerAction = () => {
    setOpenBurger(!openBurger);
  };

  const { data: email } = useFetchHotLineEmailQuery('');
  const { data: phone } = useFetchHotLinePhoneQuery('');
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const navigate = useNavigate();
  if (
    getTokenFromStorage() &&
    !pathname.includes('confirmation') &&
    !pathname.includes('receipt')
  ) {
    return <Navigate to={'/services'} />;
  }

  const handlers = {
    logout() {
      dispatch(
        logout(() => {
          navigate('auth/login');
        })
      );
    },
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div className="tw-w-full tw-shadow-md">
        <div className="tw-w-full tw-mx-auto tw-max-w-[1282px] tw-py-3.5 tw-flex tw-items-center tw-justify-between tw-gap-5 md:tw-px-5">
          <div className="tw-hidden md:tw-flex lg:tw-w-[30%] tw-justify-center tw-w-full">
            <div
              className="tw-flex tw-w-full tw-items-center tw-gap-4 tw-flex-col md:tw-flex-row hover:tw-cursor-pointer"
              onClick={() => navigate('/')}
            >
              <img
                src="/images/img_logo.svg"
                alt="Logo"
                className="tw-h-[60px] tw-object-contain"
              />
              <div className="tw-flex tw-flex-1 tw-flex-col tw-space-y-1.5 sm:tw-self-stretch">
                <p className="tw-font-opensans tw-text-[12px] tw-font-semibold tw-uppercase tw-text-gray-900">
                  Портал государственных услуг
                </p>
                <p className="tw-font-opensans tw-text-[12px] tw-font-medium tw-leading-[14px] tw-tracking-[1.00px] tw-text-[#252525B2]">
                  <>
                    Министерство Финансов
                    <br />
                    Республики Таджикистан
                  </>
                </p>
              </div>
            </div>
          </div>
          <ul className="tw-hidden md:tw-flex tw-items-center tw-gap-6">
            <li>
              <NavLink to="/services/all">
                <p className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900">
                  Все услуги
                </p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/instruction">
                <p className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900">
                  Видеоинструкции
                </p>
              </NavLink>
            </li>
          </ul>
          <div className="tw-flex lg:tw-w-[36%] tw-items-center tw-justify-between tw-gap-5 tw-w-full tw-shadow-sm md:tw-shadow-none tw-py-3 md:tw-py-0">
            <div className="tw-flex lg:tw-w-[64%] tw-justify-between tw-items-start tw-gap-5 tw-w-[80%] tw-mx-auto md:tw-mx-0">
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#25252566]">
                  Телефон горячей линии
                </p>
                {phone?.length > 0 &&
                  phone.map((item: string) => (
                    <a
                      className="tw-font-inter tw-text-[14px] tw-font-medium tw-tracking-[0.28px] tw-text-gray-900"
                      href={`tel:${item}`}
                      key={item}
                    >
                      {item}
                    </a>
                  ))}
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-0.5">
                <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#25252566]">
                  Электронный адрес
                </p>
                {email?.length > 0 &&
                  email.map((item: string) => (
                    <a
                      className="tw-font-inter tw-text-[14px] tw-font-normal tw-text-gray-900"
                      href={`mailto:${item}`}
                      key={item}
                    >
                      {item}
                    </a>
                  ))}
              </div>
            </div>
            <button
              onClick={() => navigate('/auth/login')}
              className="tw-hidden md:tw-flex tw-h-[40px] tw-min-w-[98px] tw-flex-row tw-items-center tw-justify-center tw-rounded-lg tw-bg-gradient tw-px-6 tw-text-center tw-font-inter tw-text-[14px] tw-font-bold tw-text-white sm:tw-px-5"
            >
              ВОЙТИ
            </button>
          </div>
        </div>
        <div className="tw-block md:tw-hidden tw-w-full">
          <div className="tw-w-[90%] tw-mx-auto tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-5">
            <div
              onClick={() => navigate('/')}
              className="tw-flex md:tw-hidden tw-flex-1 tw-flex-col tw-space-y-1.5 sm:tw-self-stretch"
            >
              <p className="tw-font-opensans tw-text-[10px] tw-font-semibold tw-uppercase tw-text-gray-900 tw-tracking-[1.50px]">
                Портал государственных услуг
              </p>
              <p className="tw-font-opensans tw-text-[8px] tw-font-medium tw-leading-[14px] tw-tracking-[1.00px] tw-text-[#252525B2]">
                <>
                  Министерство Финансов
                  <br />
                  Республики Таджикистан
                </>
              </p>
            </div>

            <button
              onClick={burgerAction}
              className="tw-bg-transparent tw-border-none"
            >
              <img src="/images/img_burger_default.svg" />
            </button>
          </div>

          <div
            className={`${
              openBurger ? 'tw-block' : 'tw-hidden'
            } tw-fixed tw-top-28 tw-bottom-0 tw-left-0 tw-right-0 tw-h-screen tw-bg-gradient-to-b tw-from-[#7DE2FC] tw-to-[#B9B6E5] tw-z-50`}
          >
            <div className="tw-w-[90%] tw-mx-auto tw-py-12">
              <div className="tw-w-full tw-flex tw-justify-end">
                <button
                  onClick={burgerAction}
                  className="tw-bg-transparent tw-border-none"
                >
                  <img src="/images/img_burger_open.svg" />
                </button>
              </div>
              <div className="tw-grid tw-grid-cols-1 tw-divide-y tw-w-full tw-p-5 tw-bg-white tw-rounded-xl tw-mt-12">
                <NavLink to="/services/all" onClick={burgerAction}>
                  <p className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900 tw-py-2 tw-mb-3">
                    Все услуги
                  </p>
                </NavLink>

                <NavLink to="/instruction" onClick={burgerAction}>
                  <p className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900 tw-py-2 tw-mt-2">
                    Видеоинструкции
                  </p>
                </NavLink>
              </div>

              <button
                onClick={() => navigate('/auth/login')}
                className="tw-mt-20 tw-flex tw-h-[40px] tw-w-full tw-flex-row tw-items-center tw-justify-center tw-rounded-lg tw-bg-gradient tw-text-center tw-font-inter tw-text-[14px] tw-font-bold tw-text-white"
              >
                ВОЙТИ
              </button>
            </div>
          </div>
        </div>
      </div>

      <main className={openBurger ? 'tw-hidden' : ''}>
        {isAnimationAllowed > 0 && <SnowFlake />}
        <Outlet />
      </main>

      {getTokenFromStorage() ? (
        <div className="sidenav" style={{ width: open ? '100%' : '0px' }}>
          <div className="tw-py-4 tw-pl-5">
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
              sx={{ color: '#fff' }}
            >
              <Close fontSize="large" />
            </IconButton>
          </div>
          <div className="tw-flex tw-flex-col tw-mt-8 tw-ml-8 tw-mb-10">
            <p
              className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
              onClick={() => {
                navigate('/');
                setOpen(false);
              }}
            >
              Все услуги
            </p>
            <p
              className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
              onClick={() => {
                navigate('/my-services');
                setOpen(false);
              }}
            >
              Мои услуги{' '}
            </p>
          </div>
          <div className="tw-ml-5">
            <MainMenu
              isDrawer
              closeDrawer={() => {
                setOpen(false);
              }}
              onLogout={handlers.logout}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      <Footer className="tw-mt-[72px]" email={email} phone={phone} />
    </Box>
  );
};

export default PublicContentLayout;
