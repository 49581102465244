import { FC, useMemo, useState } from 'react';
import {
  useFetchOrganisationListQuery,
  useGetCategoryListQuery,
  useGetPopularServicesQuery,
} from '../../../store/hooks/services';
import './index.scss';
import HeroSection from 'components/newDesign/HeroSection';
import GovernmentServicesSection from 'components/newDesign/GovernmentServicesSection';
import MainSpheres from 'components/newDesign/MainSpheres';
import MainPopularServices from 'components/newDesign/MainPopularServices';
import MainFAQSection from 'components/newDesign/MainFAQSection';
import { useMediaQuery } from '@mui/material';

const HomePage: FC = () => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { data: orgs } = useFetchOrganisationListQuery(selectedValue);
  const { data: categories } = useGetCategoryListQuery();
  const { data: popularServices } = useGetPopularServicesQuery();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 560px)');
  const [sectionsTab, setSectionsTab] = useState(1);

  const organisatios = useMemo(() => {
    return (
      orgs?.items?.map((item) => ({
        iconUrl: item.image,
        name: item.name,
        id: item.id,
        services: item.services,
      })) || []
    );
  }, [orgs]);

  return (
    <div className="tw-w-full tw-flex tw-flex-col lg:tw-gap-[72px] md:tw-gap-[54px] sm:tw-gap-9">
      <HeroSection />

      <GovernmentServicesSection organs={organisatios} path="/services" />

      <div className="tw-mx-auto tw-flex md:tw-hidden tw-flex-row tw-items-start tw-gap-7">
        <button
          onClick={() => setSectionsTab(1)}
          className={`tw-w-[99px] tw-h-[32px] tw-rounded-lg ${
            sectionsTab === 1
              ? 'tw-bg-[#007B9E] tw-text-white'
              : 'tw-bg-[#007B9E33] tw-text-[#007B9E]'
          } tw-twxt-center tw-text-[14px] tw-font-bold tw-font-roboto`}
        >
          Сферы
        </button>
        <button
          onClick={() => setSectionsTab(2)}
          className={`tw-w-[185px] tw-h-[32px] tw-rounded-lg ${
            sectionsTab === 2
              ? 'tw-bg-[#007B9E] tw-text-white'
              : 'tw-bg-[#007B9E33] tw-text-[#007B9E]'
          } tw-twxt-center tw-text-[14px] tw-font-medium tw-font-roboto`}
        >
          Популярные услуги
        </button>
      </div>

      {(!isSmallDevice || sectionsTab === 1) && (
        <MainSpheres categories={categories} path="/services" />
      )}
      {(!isSmallDevice || sectionsTab === 2) && (
        <MainPopularServices items={popularServices} />
      )}

      <MainFAQSection />
    </div>
  );
};

export default HomePage;
