import { useMediaQuery } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({
  cover,
  videoPath,
  desc,
}: {
  cover: string;
  videoPath: string;
  desc: string;
}) => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 660px)');
  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-items-start tw-space-y-4">
      <ReactPlayer
        height={isSmallDevice ? 200 : 320}
        width="100%"
        url={videoPath}
        light={cover} // Обложка
        controls
      />
      <p className="tw-text-[#66727F] tw-text-[16px] md:tw-text-[20px] tw-font-medium tw-leading-normal">
        {desc}
      </p>
    </div>
  );
};

export default VideoPlayer;
