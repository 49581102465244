import { api } from 'store';
import { ApiRoutes } from '../../../shared/constants/ApiRoutes';
export interface IFilterString {
  filter: string;
}

export interface IFilesRequest {
  data: FormData;
}

export interface IFileResponce {
  url: string;
}

export interface IFileDownloadRequest {
  data: IFileDownloadPayload;
}

export interface IFileDownloadPayload {
  fileName: string;
  type: any;
}

export interface IFileDownloadWithHash {
  hash: string;
}

export interface IFileDowloadResponse {
  data: any;
}

export interface IEgovOrganisation {
  id: number;
  name: string;
  description: string;
  serviceName: string;
  pageName: string;
  image: string;
  count: number;
  parentModul: string;
  services: IEgovOrganisation[];
}

export interface IGeneralContacts {
  phone: string;
  email: string;
}

export const generalApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    fetchYears: builder.query<number[], void>({
      query: () => ({ url: ApiRoutes.generalYearList }),
    }),
    fetchHotLinePhone: builder.query({
      query: () => ({ url: ApiRoutes.generalHotLinePhone }),
    }),
    fetchHotLineEmail: builder.query({
      query: () => ({ url: ApiRoutes.generalHotLineEmail }),
    }),
    fetchRejectReasons: builder.query<
      IListResponse<ValueId>,
      Nullable<{
        type?: number;
      }> | void
    >({
      query: (data) => ({
        url: ApiRoutes.generalRejectReasonsList,
        method: 'POST',
        data,
      }),
    }),
    fetchCities: builder.query<IListResponse<ValueId>, void>({
      query: () => ({
        url: ApiRoutes.generalCityList,
        method: 'POST',
        data: {},
      }),
    }),
    fetchDoctypes: builder.query<IListResponse<ValueId>, void>({
      query: () => ({
        url: ApiRoutes.generalDoctypeList,
        method: 'POST',
        data: {},
      }),
    }),
    fetchOrganisationTypeList: builder.query<IListResponse<ValueId>, void>({
      query: () => ({
        url: ApiRoutes.generalOrgTypeList,
        method: 'POST',
      }),
    }),
    fetchOrganisationList: builder.query<
      IListResponse<IEgovOrganisation>,
      void
    >({
      query: () => ({
        url: ApiRoutes.generalOrgList,
        method: 'POST',
        data: {},
      }),
    }),
    fetchEgovGovernments: builder.query<IListResponse<ValueId>, void>({
      query: () => ({
        url: ApiRoutes.generalEgovGovernList,
        method: 'POST',
        data: {},
      }),
    }),
    fetchEgovInnList: builder.query<IListResponse<ValueId>, void>({
      query: () => ({
        url: ApiRoutes.generalUserInnList,
        method: 'POST',
        data: {},
      }),
    }),
    fetchEgovDocTypes: builder.query<IListResponse<ValueId>, void>({
      query: () => ({
        url: ApiRoutes.generalEgovDoctypeList,
        method: 'POST',
        data: {},
      }),
    }),
    fetchEgovCountList: builder.query<number[], void>({
      query: () => ({
        url: ApiRoutes.generalEgovCountList,
        method: 'POST',
        data: {},
      }),
    }),

    // Files
    fetchDownloadFiles: builder.mutation<
      IFileDowloadResponse,
      IFileDownloadRequest
    >({
      query: (data) => ({
        url: '/api/file/DownloadFile2',
        method: 'POST',
        params: data.data,
      }),
    }),

    getEgovContacts: builder.query<IGeneralContacts, void>({
      query: () => ({
        url: ApiRoutes.generalEgovContacts,
        method: 'POST',
        data: {},
      }),
    }),
  }),
});

export const {
  useFetchYearsQuery,
  useFetchRejectReasonsQuery,
  useFetchCitiesQuery,
  useFetchDoctypesQuery,
  useFetchOrganisationTypeListQuery,
  useFetchOrganisationListQuery,
  useFetchEgovGovernmentsQuery,
  useFetchEgovInnListQuery,
  useFetchEgovDocTypesQuery,
  useFetchEgovCountListQuery,
  useFetchDownloadFilesMutation,
  useGetEgovContactsQuery,
  useFetchHotLineEmailQuery,
  useFetchHotLinePhoneQuery
} = generalApi;
