import DocumentSearchIconWithTitle from './DocumentSearchIconWithTitle';
import React, { Suspense, useState } from 'react';
import AlertModal from 'routes/public/services/modals/AlertModal';
import InfoModal from 'routes/public/services/modals/InfoModal';

export default function MainPopularServices({ items }: { items: any }) {
  const [serviceId, setServiceId] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  return (
    <>
      {/* popular services section */}
      <div className="tw-flex tw-flex-col tw-items-center">
        <div className="tw-mx-auto tw-max-w-[1282px] tw-w-full tw-flex tw-flex-col tw-gap-[35px] tw-px-5 md:tw-px-0">
          <div className="tw-hidden md:tw-flex tw-items-center tw-justify-between tw-gap-5">
            <p className="tw-text-[24px] tw-font-roboto tw-font-semibold tw-text-gray-900 md:tw-text-[26px] lg:tw-text-[28px]">
              Популярные услуги
            </p>
            <button className="tw-hidden md:tw-flex tw-h-[32px] tw-min-w-[124px] tw-flex-row tw-items-center tw-justify-center tw-rounded-lg tw-bg-[#007B9E33] md:tw-px-6 tw-text-center tw-font-inter tw-text-[14px] tw-font-medium tw-text-cyan-800 tw-px-5">
              Все услуги
            </button>
          </div>
          <div className="tw-grid lg:tw-grid-cols-4 tw-justify-center tw-gap-6 md:grid-cols-2 tw-grid-cols-1 tw-py-7 md:tw-py-0">
            <Suspense fallback={<div>Loading feed...</div>}>
              {items?.map((d: { id: number; value: string }, index: number) => (
                <DocumentSearchIconWithTitle
                  handleClick={() => {
                    setServiceId(d.id);
                    setModalOpen(true);
                  }}
                  documentTitle={d.value}
                  key={'cardbox' + index}
                />
              ))}
            </Suspense>
          </div>
        </div>
      </div>

      {serviceId && (
        <>
          <InfoModal
            serviceId={serviceId}
            isOpen={modalOpen}
            handleClose={() => setModalOpen(false)}
            getServiceClick={() => setModalOpen1(true)}
          />

          <AlertModal
            isOpen={modalOpen1}
            handleClose={() => setModalOpen1(false)}
          />
        </>
      )}
    </>
  );
}
