import React from 'react';
import AliceCarousel, { Props } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

type SliderComponentProps = Props &
  Partial<{
    items: React.ReactElement[];
    centerMode: string | boolean;
    magnifiedIndex?: number;
    activeSlideCSS?: string;
    [x: string]: any;
  }>;

const Slider = React.forwardRef<AliceCarousel, SliderComponentProps>(
  (
    {
      items = [],
      activeIndex = 0,
      centerMode,
      magnifiedIndex = 0,
      activeSlideCSS = 'scale-75',
      ...props
    },
    ref
  ) => {
    const isSmall = (index: number) => {
      if (props?.activeIndex + magnifiedIndex >= items?.length) {
        return index !== props?.activeIndex + magnifiedIndex - items?.length;
      } else {
        return index !== props.activeIndex + magnifiedIndex;
      }
    };

    // Проверка, если элементов только один
    const isSlidable = items.length > 3;

    // Логика для обеспечения стабильного вида слайдера при малом количестве элементов
    const getSliderClassNames = () => {
      if (items.length <= 2) {
        return 'tw-flex tw-items-center tw-justify-center'; // Центрируем элементы
      }
      return ''; // Для большего количества элементов ничего не меняем
    };

    // Если есть только 1 элемент, то отключаем авто-переход и ограничиваем ширину слайдера
    const slideItems = centerMode
      ? items?.map((child: React.ReactElement, index: number) => {
          if (isSmall(index)) {
            return React.cloneElement(child, {
              ...child.props,
              className: [child.props?.className, activeSlideCSS]
                .filter(Boolean)
                .join(' '),
            });
          }
          return React.cloneElement(child);
        })
      : items;

    return (
      <AliceCarousel
        items={slideItems}
        infinite={isSlidable} // Отключаем бесконечный слайд, если один элемент
        ref={ref}
        {...props}
        touchTracking
        mouseTracking
        disableButtonsControls
        autoPlay={isSlidable} // Отключаем авто-переход для одного элемента
        autoPlayInterval={2000}
        // @ts-ignore
        className={getSliderClassNames()} // Используем className для стилизации
      />
    );
  }
);

export { Slider };
