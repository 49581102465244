import React, { useState, useEffect } from 'react';
import ServicesSection1 from './sections/ServicesSection1';
import ServicesSection2 from './sections/ServicesSection2';
import { useLocation } from 'react-router-dom';

const Services: React.FC = () => {
  const location = useLocation();
  const [categoryId, setCategoryId] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get('category');
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCategoryId(params.get('category'));
  }, [location.search]);

  const updateCategoryId = (newCategory: string) => {
    const params = new URLSearchParams(location.search);
    params.set('category', newCategory);

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${params.toString()}`
    );

    setCategoryId(newCategory);
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-10 tw-my-9">
      <ServicesSection1
        categoryId={categoryId}
        setCategoryId={updateCategoryId}
      />
      <ServicesSection2 categoryId={categoryId} />
    </div>
  );
};

export default Services;
