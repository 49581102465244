import React from 'react';

const FifthText = () => {
  return (
    <div>
      <p>
        <span className="tw-font-bold">Аутентификация</span>— это процесс
        подтверждения личности пользователя. Этот процесс необходим для
        повышения безопасности и уверенности в том, что доступ к учетной записи
        имеют только уполномоченные пользователи.
      </p>
      <p className="tw-mt-[10px]">Шаги для прохождения аутентификации:</p>
      <ul className="tw-list-decimal tw-pl-[30px]">
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Перейдите на страницу учетной записи:
          </span>
          Войдите в свою учетную запись на Портале государственных услуг,
          используя логин и пароль, которые вы установили при регистрации.
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Нажмите на кнопку «Аутентификация»:
          </span>
          На странице вашей учетной записи будет кнопка "Аутентификация".
          Нажмите на нее, чтобы начать процесс аутентификации.
        </li>
        <li className="tw-mt-[10px]">
          Загрузите необходимые документы: После того как вы нажмете кнопку для
          аутентификации, вам будет предложено загрузить следующие документы:
          <ul className="tw-list-disc tw-pl-[30px]">
            <li className="tw-mt-[10px]">
              <span className="tw-font-bold">
                Паспорт гражданина Республики Таджикистан (Передняя сторона):
              </span>{' '}
              Загрузите изображение передней стороны вашего паспорта.
            </li>
            <li className="tw-mt-[10px]">
              <span className="tw-font-bold">
                Паспорт гражданина Республики Таджикистан (Задняя сторона):
              </span>{' '}
              Загрузите изображение задней стороны вашего паспорта.
            </li>
            <li className="tw-mt-[10px]">
              <span className="tw-font-bold">ИНН:</span> Загрузите документ,
              подтверждающий ваш индивидуальный номер налогоплательщика (ИНН).
            </li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">Загрузка и проверка документов:</span>
          После загрузки всех указанных документов они будут отправлены на
          проверку администратору системы. Администратор проверит подлинность
          документов и соответствие их требованиям.
        </li>
        <li className="tw-mt-[10px]">
          <span className='tw-font-bold'>
             Завершение аутентификации:
          </span>
           Если все документы будут успешно
          проверены и признаны подлинными, ваша учетная запись будет
          аутентифицирована, и вы получите уведомление о завершении процесса.
        </li>
      </ul>
    </div>
  );
};

export default FifthText;
