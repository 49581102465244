import { api } from 'store';
import { AuthApiRoutes } from '../../../shared/constants/ApiRoutes';

export interface IUserCreate {
  name: string;
  surName: string;
  patronicName: string;
  login: string;
  passWord: string;
  confirmPassWord: string;
  inn: string;
  phone: string;
  email: string;
  passportNumber: string;
  address: string;
  imageUrl: string;
}

export interface ILegalCreate {
  orgName: string;
  orgINN: string;
  orgAdress: string;
  name: string;
  surName: string;
  patronicName: string;
  login: string;
  passWord: string;
  confirmPassWord: string;
  inn: string;
  phone: string;
  email: string;
  passportNumber: string;
  address: string;
  imageUrl: string;
}

export interface IChangePasswordRequest {
  oldPassWord: string;
  newPassWord: string;
  passWordAgain?: string;
  timeStamp?: string;
}

const egovApplicationApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createUser: builder.mutation<void, IUserCreate>({
      query: (data) => ({
        url: AuthApiRoutes.createUser,
        method: 'POST',
        data,
      }),
      invalidatesTags: [],
    }),
    createLegal: builder.mutation<void, ILegalCreate>({
      query: (data) => ({
        url: AuthApiRoutes.createLegal,
        method: 'POST',
        data,
      }),
    }),
    updateChangePassword: builder.mutation<void, IChangePasswordRequest>({
      query: (data) => ({
        url: AuthApiRoutes.changePassword,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useUpdateChangePasswordMutation,
  useCreateLegalMutation,
} = egovApplicationApi;
