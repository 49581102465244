import { useMediaQuery } from '@mui/material';
import { Input } from './Input';
import { CloseSVG } from './Input/close';
import React from 'react';

export default function HeroSection() {
  const [searchBarValue, setSearchBarValue] = React.useState('');
  const isSmallDevice = useMediaQuery('only screen and (max-width : 660px)');

  return (
    <div
      style={{
        backgroundImage: isSmallDevice
          ? "url('images/img_hero_section_bg_mobile.png')"
          : "url('images/img_hero_section_bg.png')",
      }}
      className="md:tw-h-[450px] tw-bg-cover tw-w-full tw-bg-no-repeat tw-bg-right-top tw-h-[300px]"
    >
      <div className="tw-flex tw-items-start tw-h-full tw-mx-auto tw-max-w-[1282px] tw-w-[90%] tw-flex-col tw-justify-center">
        <div className="tw-flex tw-flex-col tw-gap-[30px] tw-w-full md:tw-w-[65%] tw-items-start tw-justify-center">
          <div className="tw-w-full tw-flex tw-flex-col tw-gap-6">
            <p className="tw-w-full lg:tw-text-[32px] tw-font-roboto tw-font-semibold tw-leading-tight tw-text-white md:tw-text-[30px] tw-text-[22px]">
              Вся государственная финансовая система собрана на одном удобном и
              доступном веб-сайте
            </p>
            <p className="tw-text-[16px] tw-font-roboto tw-font-normal tw-text-white">
              Пользуйтесь госуслугами, не выходя из дома!
            </p>
          </div>
          <div className="tw-flex tw-items-center tw-gap-6 tw-flex-row">
            <Input
              name="search"
              placeholder={`Введите запрос...`}
              value={searchBarValue}
              onChange={(e) => setSearchBarValue(e.target.value)}
              suffix={
                searchBarValue?.length > 0 ? (
                  <CloseSVG
                    onClick={() => setSearchBarValue('')}
                    height={24}
                    width={24}
                    fillColor="#007b9eff"
                  />
                ) : (
                  <img
                    src="images/img_search_cyan_800.svg"
                    alt="Search"
                    className="tw-h-[24px] tw-w-[24px] tw-object-contain"
                  />
                )
              }
              className="tw-flex tw-h-[48px] tw-items-center tw-justify-center tw-gap-4 tw-rounded-lg tw-bg-white tw-px-6 tw-text-[14px] tw-text-[#25252599]"
            />
            <img
              src="images/img_close.svg"
              alt="Close"
              className="tw-h-[32px] sm:tw-w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
