import { default as ModalProvider } from 'react-modal';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  isOpen: boolean;
  handleClose: () => void;
}

export default function AlertModal({ isOpen, handleClose, ...props }: Props) {
  const navigate = useNavigate();
  return (
    <ModalProvider
      {...props}
      isOpen={isOpen}
      className="tw-w-full tw-h-full tw-bg-[#000] tw-bg-opacity-40 tw-outline-none"
    >
      <div>
        <div className="tw-max-w-[1200px] tw-w-[96%] md:tw-w-[85%] tw-mx-auto md:tw-px-10 tw-px-5 tw-rounded-t-[24px] tw-bg-white tw-mt-6">
          <div className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-start tw-justify-center md:tw-py-10 md:px-0 tw-py-5 tw-px-5">
            <div className="tw-my-2 tw-flex tw-flex-1 tw-gap-5 md:tw-self-center tw-flex-col md:tw-flex-row tw-self-stretch">
              <img
                src="/images/img_iconoir_page_search_red_500.svg"
                alt="Iconoirpage"
                className="tw-hidden md:tw-block tw-h-[48px]"
              />
              {/* <p className="md:tw-w-[58%] tw-overflow-hidden tw-overflow-ellipsis tw-font-roboto tw-text-[18px] tw-pt-4 md:tw-pt-0 md:tw-text-[20px] tw-font-medium tw-leading-tight tw-text-red-500 tw-w-full">
                Выдача выписок из лицевого счета о состоянии расчетов с
                бюджетом, а также по социальным платежам
              </p> */}
            </div>
            <button
              onClick={handleClose}
              className="tw-bg-transparent tw-border-none tw-cursor-pointer tw-self-end md:tw-self-start"
            >
              <img
                src="/images/img_arrow_right_gray_900_1.svg"
                alt="Arrowright"
                className="tw-h-[18px]"
              />
            </button>
          </div>
        </div>
        <div className="tw-max-w-[1200px] tw-w-[96%] md:tw-w-[85%] tw-mx-auto tw-flex tw-flex-col tw-items-center tw-gap-10 tw-rounded-b-[24px] tw-bg-gray-100 md:tw-p-12 tw-p-5">
          <img
            src="/images/img_1_1.svg"
            alt="Image"
            className="tw-h-[150px] md:tw-h-[300px] tw-w-full md:tw-w-[26%] tw-object-contain"
          />
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-10">
            <p className="tw-text-center md:tw-text-[24px] tw-font-roboto tw-font-normal tw-leading-snug tw-text-red-500 tw-text-[16px]">
              Для того чтобы воспользоваться данной услугой, Вам необходимо
              войти или авторизоваться в информационной системе управления
              Государственными Финансами.
            </p>
            <button
              onClick={() => navigate('/auth/login')}
              className="tw-flex tw-h-[40px] tw-min-w-[250px] tw-flex-row tw-items-center tw-justify-center tw-rounded-lg tw-bg-cyan-800 md:tw-px-[34px] tw-text-center tw-text-[16px] tw-font-bold tw-text-white tw-px-5"
            >
              Войти
            </button>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}
