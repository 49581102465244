import React from 'react';

const EleventhText = () => {
  return (
    <div>
      <p>
        После того как заявка пользователя перейдет на стадию «На исполнение», и
        если исполнитель решит, что необходимо обсудить с заявителем
        дополнительные детали заявки (например, неполноту заполнения,
        корректность документов и т. д.), будет создан чат между вами и
        исполнителем для дальнейшего обсуждения.{' '}
      </p>
      <p className="tw-mt-[10px]">Шаги для начала переписки с исполнителем:</p>
      <ul className="tw-list-decimal tw-pl-[30px]">
        <li className="tw-mt-[10px]">
            <span className='tw-font-bold'>Получите уведомление: </span>
           Как только исполнитель создает чат, система
          отправит вам уведомление о том, что чат с исполнителем был создан
        </li>
        <li className="tw-mt-[10px]">
            <span className='tw-font-bold'> Перейдите в чат:</span>
           Перейдите в оформленную услугу и после нажмите на
          кнопку "Чат с исполнителем".
        </li>
        <li className="tw-mt-[10px]">
            <span className='tw-font-bold'>
                Начните переписку:
            </span>
            Найдите чат с исполнителем, связанный с вашей
          заявкой. Откройте его, и вы сможете начать переписку, задать вопросы
          или предоставить дополнительные данные, которые могут потребоваться
          для корректного завершения обработки вашей заявки.
        </li>
        <li className="tw-mt-[10px]">
            <span className='tw-font-bold'> Завершение чата:</span>
           Как только исполнитель убедится, что заявка
          заполнена корректно и все вопросы решены, он завершит исполнение
          заявки, и чат будет закрыт.
        </li>
      </ul>
      <p className='tw-mt-[10px]'>
      Важные моменты:
      </p>
      <ul className='tw-list-disc tw-pl-[30px]'>
        <li className='tw-mt-[10px]' >Не забывайте проверять уведомления, чтобы своевременно начать переписку с исполнителем.</li>
        <li className='tw-mt-[10px]' >Все важные вопросы и уточнения касательно заявки должны быть обсуждены через чат для ускорения процесса ее обработки.</li>
        <li  className='tw-mt-[10px]'> После завершения переписки и подтверждения полноты заявки, чат будет закрыт, и ваша заявка перейдет к финальной стадии обработки.</li>
      </ul>
    </div>
  );
};

export default EleventhText;
