import React from 'react';

const SecondText = () => {
  return (
    <>
      <div>
        <p>
          Для того чтобы создать учетную запись, необходимо пройти процедуру
          регистрации, которая включает несколько шагов, в зависимости от того,
          регистрируется ли физическое или юридическое лицо.
        </p>
        <p className='tw-mt-[10px] tw-font-bold'>1. Регистрация физического лица</p>
        <p className='tw-mt-[10px]'>Чтобы зарегистрироваться как физическое лицо, необходимо заполнить следующие поля на странице регистрации:</p>
        <ul className='tw-list-disc tw-pl-[30px]'>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Имя:</span>  Введите ваше имя.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Фамилия:</span>  Введите вашу фамилию</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Отчество:</span>  Введите ваше отчество.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Серийный номер паспорта:</span>  Укажите ваш серийный номер паспорта.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Пароль:</span>  Создайте безопасный пароль для своей учетной записи.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Подтверждение пароля: </span> Повторите введенный пароль для подтверждения.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>ИНН:</span>  Укажите ваш индивидуальный номер налогоплательщика (ИНН).</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Email:</span>  Введите действующий адрес электронной почты.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Телефон:</span>  Укажите номер мобильного телефона.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Получить код:</span>  Запросите код подтверждения, который будет отправлен на ваш телефон или email.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Ввод кода:</span>  Введите полученный код для завершения регистрации.</li>
        </ul>
      </div>
      <div> 
        <p className='tw-mt-[10px]'>После заполнения и подтверждения всех полей, учетная запись для физического лица будет успешно создана.</p>
        <p className='tw-mt-[10px]'>2. Регистрация юридического лица</p>
        <p className='tw-mt-[10px]'>Для регистрации юридического лица необходимо предоставить информацию как о самой организации, так и о руководителе.</p>
        <p className='tw-mt-[10px]'>Данные организации:</p>
        <ul className='tw-list-disc tw-pl-[30px]'>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Название организации: </span>Укажите полное наименование вашей организации.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>ИНН организации: </span>Введите ИНН вашей организации.</li>
            <li className='tw-mt-[10px]'><span className='tw-font-bold'>Адрес организации: </span>Укажите юридический адрес организации.</li>
        </ul>
      <p className='tw-mt-[10px]'>Данные руководителя:</p>
      <ul className='tw-list-disc tw-pl-[30px]'>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Имя: </span>Укажите имя руководителя.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Фамилия:</span>Укажите имя руководителя.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Отчество:</span>Укажите отчество руководителя.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Серийный номер паспорта: </span>Укажите серийный номер паспорта руководителя.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Пароль:</span>Укажите серийный номер паспорта руководителя.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Подтверждение пароля: </span>Повторите введенный пароль для подтверждения.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>ИНН: </span>Укажите ИНН руководителя.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Email:</span>Введите действующий адрес электронной почты.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Телефон:</span>Укажите контактный номер телефона.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Получить код: </span>Запросите код подтверждения, который будет отправлен на указанный телефон или email.</li>
        <li className='tw-mt-[10px]'><span className='tw-font-bold'>Ввод кода: </span>Введите полученный код для завершения регистрации.</li>
      </ul>
      <p className='tw-mt-[10px]'>После заполнения и подтверждения всех полей, учетная запись для юридического лица будет успешно создана.</p>
      </div>
    </>
  );
};

export default SecondText;
