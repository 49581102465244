import React from 'react';

const FirstText = () => {
  return (
    <>
      <div>
        <p>
          <span className="tw-font-[700]">
            Портал государственных услуг Министерства Финансов Республики
            Таджикистан
          </span>
          — это электронная платформа, созданная для предоставления гражданам и
          юридическим лицам доступа к государственным услугам, предоставляемым
          Министерством финансов Таджикистана. Портал позволяет пользователям
          удобно и быстро получать нужные услуги в цифровом формате, что
          значительно упрощает взаимодействие с государственными органами и
          ускоряет процесс получения услуг.
        </p>
      </div>
      <div>
        <p className="tw-mt-[10px]">Основные возможности портала:</p>
        <ul className="tw-list-disc tw-mt-[10px] tw-pl-[30px]">
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Создание учетной записи:</span>{' '}
            Граждане могут создать личную учетную запись на портале, что
            позволяет им эффективно управлять своими запросами и следить за
            статусом поданных заявок.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Доступ к услугам:</span> После
            регистрации на портале пользователь может найти и выбрать нужную
            услугу из широкого перечня, который включает более 40 различных
            услуг Министерства финансов.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Отправка запросов:</span> Портал
            позволяет гражданам и организациям подавать электронные запросы для
            получения различных государственных услуг без необходимости личного
            визита в государственные учреждения.
          </li>
        </ul>
      </div>

      <div>
        <p className="tw-mt-[10px]">Пример услуг, доступных на портале:</p>
        <ul className="tw-list-decimal tw-pl-[30px] tw-mt-[10px]">
          <li className='tw-mt-[10px]'>
            <span className='tw-font-bold'> Документ, подтверждающий статус резидента Республики Таджикистан:</span>
            Услуга для получения официального подтверждения статуса резидента.
          </li>
          <li className='tw-mt-[10px]'>
            <span className='tw-font-bold'>Сертификат профессионального бухгалтера:</span>
             Услуга для получения сертификата, подтверждающего квалификацию бухгалтера.
          </li>
          <li className='tw-mt-[10px]'>
            <span className='tw-font-bold'> Квалификационный аттестат аудитора:</span>
            Услуга для получения аттестата, который подтверждает квалификацию аудитора.
          </li>
          <li className='tw-mt-[10px]'>
            <span className='tw-font-bold'> Деятельность ломбарда:</span>
            Услуга для регистрации и получения лицензии на осуществление деятельности ломбарда.
          </li>
          <li className='tw-mt-[10px]'>
            <span className='tw-font-bold'> Биржевая деятельность:</span>
            Услуга для получения разрешения на осуществление биржевой деятельности.
          </li>
          <li className='tw-mt-[10px]'>
            <span className='tw-font-bold'>Биржевая деятельность:</span>
             Услуга для получения разрешения на осуществление биржевой деятельности.
          </li>
        </ul>
      </div>
    </>
  );
};

export default FirstText;
