import { api } from 'store';
import { ApiRoutes, AuthApiRoutes } from '../../../shared/constants/ApiRoutes';

export interface IUser {
  id: number;
  value: string;
  login: string;
}

export interface AvatarUrlBody {
  url: string;
}

export interface ISupportSendReques {
  regUser: number;
  message: string;
  createAt?: string;
  updateAt?: string;
}

export interface IUserprofileDetails {
  id: number;
  name: string;
  surName: string;
  displayName: string;
  inn: string;
  companyName: string;
  phone: string;
  email: string;
  avatar: string;
  authorized: true;
  userType: number;
  orgName?: string;
  orgINN?: string;
  orgAdress?: string;
  roles: number[];
  userCompany: {
    id: number;
    inn: string;
    name: string;
    address: string;
    logo: string;
  };
}

interface IChangeEmailRequest {
  id: number;
  email: string;
  timeStamp?: string;
}

interface IChangeInnRequest {
  id: number;
  inn: string;
  timeStamp?: string;
}

interface ICreateCard {
  userId: number;
  cvc: string;
  cartNumber: string;
  bankName: string;
  nameAndSurname: string;
  expireDate: string;
  createAt: string;
  updateAt: string;
}
interface Item {
  id: number;
  userId: number;
  cartNumber: string;
  bankName: string;
  bankLogo: string;
  nameAndSurname: string;
  expireDate: string;
}

interface IGetItem {
  items: Item[];
  total: number;
}

export interface IOrderCriteria {
  orderColumn: number;
  direction: number;
}

export interface IRequestBody {
  ids: number[] | null;
  filters: Record<string, any>;
  orderBy: IOrderCriteria | Record<string, any>;
  pageInfo: {
    pageNumber: number;
    pageSize: number;
  };
}

export interface IDocument {
  id: number;
  userId: number;
  docPath: string;
  docName: string;
  docType: number;
}

export interface IUserDocumentCreate {
  userId: number | undefined;
  docPath: string;
  docName: string;
  docType: number;
  createAt?: string;
  updateAt?: string;
}

interface IUserDocumentUpdate extends IUserDocumentCreate {
  id?: number;
}

const egovApplicationApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    // Finance reports create
    getUser: builder.query({
      query: (id) => ({
        url: AuthApiRoutes.getUser + id,
        method: 'GET',
      }),
    }),
    getOrg: builder.query({
      query: (id) => ({
        url: AuthApiRoutes.getOrg + id,
        method: 'GET',
      }),
    }),
    getAvailableUsers: builder.mutation<IListResponse<IUser>, string>({
      query: (data) => ({
        url: ApiRoutes.getAvailableUsers,
        method: 'POST',
        data: {
          smart: data,
        },
      }),
    }),

    getUserprofileDetails: builder.query<IUserprofileDetails, void>({
      query: (data) => ({
        url: ApiRoutes.profileDetail,
        method: 'POST',
        data,
      }),
    }),
    userProfileUserSearch: builder.query({
      query: (data) => ({
        url: ApiRoutes.userProfileUserSearch,
        method: 'POST',
        data,
      }),
    }),
    userProfileUserAuthorize: builder.mutation({
      query: (data) => ({
        url: ApiRoutes.userProfileUserAuthorize,
        method: 'POST',
        data,
      }),
    }),
    userUpdate: builder.mutation({
      query: (data) => ({
        url: AuthApiRoutes.userUpdate,
        method: 'POST',
        data,
      }),
    }),
    userLegalUpdate: builder.mutation({
      query: (data) => ({
        url: AuthApiRoutes.updateLegal,
        method: 'POST',
        data,
      }),
    }),
    userProfileGet: builder.query({
      query: (id) => ({
        url: ApiRoutes.userProfileGet + id,
        method: 'GET',
      }),
    }),
    isAuthorized: builder.query({
      query: (data) => ({
        url: ApiRoutes.userProfileIsAuthorized,
        method: 'POST',
        data,
      }),
    }),
    sendSupportMessage: builder.mutation<void, ISupportSendReques>({
      query: (data) => ({
        url: ApiRoutes.supportSendMessage,
        method: 'POST',
        data,
      }),
    }),

    changeEmail: builder.mutation<void, IChangeEmailRequest>({
      query: (data) => ({
        url: AuthApiRoutes.changeEmail,
        method: 'POST',
        data,
      }),
    }),
    changeInn: builder.mutation<void, IChangeInnRequest>({
      query: (data) => ({
        url: AuthApiRoutes.changeInn,
        method: 'POST',
        data,
      }),
    }),
    saveAvatar: builder.mutation<void, AvatarUrlBody>({
      query: (data) => ({
        url: ApiRoutes.saveAvatar,
        method: 'POST',
        data,
      }),
    }),
    deleteAvatar: builder.mutation<void, { text?: string }>({
      query: (data) => ({
        url: ApiRoutes.deleteAvatar,
        method: 'POST',
        data,
      }),
    }),
    createCards: builder.mutation<void, ICreateCard>({
      query: (data) => ({
        url: ApiRoutes.createCards,
        method: 'POST',
        data: data,
      }),
    }),
    getCards: builder.query<IGetItem, IRequestBody>({
      query: (data) => ({
        url: ApiRoutes.getCards,
        method: 'POST',
        data,
      }),
    }),
    deleteCards: builder.mutation<ICreateCard, { id: number }>({
      query: (data) => ({
        url: ApiRoutes.deleteCards,
        method: 'POST',
        data,
      }),
    }),
    getDocuments: builder.query<IListResponse<IDocument>, IRequestBody>({
      query: (data) => ({
        url: ApiRoutes.getDocuments,
        method: 'POST',
        data,
      }),
    }),
    createDocuments: builder.mutation<void, IUserDocumentCreate>({
      query: (data) => ({
        url: ApiRoutes.createDocuments,
        method: 'POST',
        data,
      }),
    }),
    updateDocuments: builder.mutation<void, IUserDocumentUpdate>({
      query: (data) => ({
        url: ApiRoutes.updateDocuments,
        method: 'POST',
        data,
      }),
    }),

    addCommentToService: builder.mutation<void, any>({
      query: (data) => ({
        url: ApiRoutes.createCommentToService,
        method: 'POST',
        data,

      })
    }),

    addRatingToService: builder.mutation<void, any>({
      query: (data) => ({
        url: ApiRoutes.createRatingToService,
        method: 'POST',
        data,

      })
    }),
    authorizeMe: builder.mutation({
      query: () => ({
        url: ApiRoutes.authorizeMe,
        method: 'POST',
      }),
    }),
    sendMessageToUserForAuthorize: builder.mutation({
      query: (data) => ({
        url: ApiRoutes.userProfileSensMessageToAuthorized,
        method: 'POST',
        data
      }),
    }),
  }),
});

export const {
  useAuthorizeMeMutation,
  useGetAvailableUsersMutation,
  useSendSupportMessageMutation,
  useGetUserprofileDetailsQuery,
  useChangeEmailMutation,
  useChangeInnMutation,
  useSaveAvatarMutation,
  useDeleteAvatarMutation,
  useCreateCardsMutation,
  useGetCardsQuery,
  useDeleteCardsMutation,
  useGetDocumentsQuery,
  useCreateDocumentsMutation,
  useUpdateDocumentsMutation,
  useIsAuthorizedQuery,
  useLazyGetUserQuery,
  useGetUserQuery,
  useLazyGetOrgQuery,
  useUserProfileUserSearchQuery,
  useLazyUserProfileUserSearchQuery,
  useUserProfileUserAuthorizeMutation,
  useUserUpdateMutation,
  useUserProfileGetQuery,
  useUserLegalUpdateMutation, useAddCommentToServiceMutation, useSendMessageToUserForAuthorizeMutation, useAddRatingToServiceMutation
} = egovApplicationApi;
