import React from 'react';

const EighthText = () => {
  return (
    <div>
      <p>
        Для того чтобы получить услугу, необходимо выполнить несколько шагов
        после того, как ваша заявка будет обработана.
      </p>
      <p className="tw-mt-[10px]">Шаги для получения услуги:</p>
      <ul className="tw-list-decimal tw-pl-[30px]">
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold"> Обработка заявки: </span>
          После того как ваша заявка будет успешно обработана, предоставляемый
          документ будет оформлен на бланке Министерства Финансов, с учетом всех
          необходимых реквизитов:
          <ul className="tw-list-disc tw-pl-[30px]">
            <li className="tw-mt-[10px] tw-font-bold">Подпись</li>
            <li className="tw-mt-[10px] tw-font-bold">Печать</li>
            <li className="tw-mt-[10px] tw-font-bold">
              Электронная цифровая подпись.
            </li>
            <li className="tw-mt-[10px] ">
              {' '}
              <span className="tw-font-bold">QR код</span> для подтверждения
              подлинности цифрового документа.
            </li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          Получение уведомления: После оформления и подготовки документа на
          бланке Министерства Финансов, на номер телефона пользователя придет
          смс-сообщение, уведомляющее о том, что документ готов и доступен для
          получения.
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Получение электронного документа:
          </span>
          Вы можете получить электронную версию документа в своем личном
          кабинете на портале. Для этого нужно:
          <ul className="tw-list-disc tw-pl-[30px]">
            <li className="tw-mt-[10px]">Войти в свою учетную запись</li>
            <li className="tw-mt-[10px]">
              Перейти в раздел, где хранятся все документы, и найти нужный
              документ.
            </li>
            <li className="tw-mt-[10px]">качайте его в формате PDF.</li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">Получение бумажной версии:</span>
          Если вам нужна бумажная версия документа, вы можете получить ее в
          общем отделе Министерства Финансов. В этом случае вам необходимо:
          <ul className="tw-list-disc tw-pl-[30px]">
            <li className="tw-mt-[10px]">
              Посетить отдел Министерства Финансов
            </li>
            <li className="tw-mt-[10px]">
              Предоставить подтверждающие данные или квитанцию о том, что вы
              подали заявку и оплатили услугу.
            </li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Подтверждение подлинности документа:
          </span>{' '}
          Для подтверждения подлинности цифрового документа вы можете
          использовать QR код, который будет прикреплен к документу.
          Сканирование QR кода позволит вам удостовериться в подлинности
          предоставленного документа
        </li>
      </ul>
      <p className='tw-mt-[10px]'>Важные моменты:</p>
      <ul className='tw-list-disc tw-pl-[30px]'>
        <li className='tw-mt-[10px]'>
           Документ, полученный через портал, будет иметь официальную
          электронную цифровую подпись, которая подтверждает его подлинность и
          юридическую силу.
        </li>
        <li className='tw-mt-[10px]'>
           Документ, полученный через портал, будет иметь официальную
          электронную цифровую подпись, которая подтверждает его подлинность и
          юридическую силу.
        </li>
        <li className='tw-mt-[10px]'>QR код в документе позволяет вам проверить его подлинность через систему Министерства Финансов.</li>
      </ul>
    </div>
  );
};

export default EighthText;
