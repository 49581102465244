import EighthText from './AcardionText/EighthText';
import EleventhText from './AcardionText/EleventhText';
import FifthText from './AcardionText/FifthText';
import FirstText from './AcardionText/FirstText';
import FourthTetx from './AcardionText/FourthTetx';
import NinthText from './AcardionText/NinthText';
import SecondText from './AcardionText/SecondText';
import SeventhText from './AcardionText/SeventhText';
import SixthText from './AcardionText/SixthText';
import ThirdText from './AcardionText/ThirdText';
import DigitalSignatureKeyInfo from './DigitalSignatureKeyInfo';
import React, { Suspense } from 'react';

const faqGrid = [
  { titleText: '1.	Что такое Портал государственных услуг Министерства Финансов и какие услуги он предоставляет?',
    children: <FirstText/>
   },
  {
    titleText: '2.	Как создать учетную запись?',
    children : <SecondText/>
  },
  {
    titleText: '3.	Как войти в свою учетную запись?',
    children :<ThirdText/>,
  },
  {
    titleText:
      '4.	Как восстановить пароль?',
      children : <FourthTetx/>,
  },
  {
    titleText:
      '5.	Что такое аутентификация на и как ее пройти?',
      children :<FifthText/>,
  },
  { titleText: '6.	Как подать заявку на получение услуги?' ,
    children : <SixthText/>,
   },
  {
    titleText: '7.	Как оплатить услугу?',
    children : <SeventhText/>
  },
  {
    titleText:
      '8.	Как получить услугу?',
      children : <EighthText/>
  },
  {
    titleText:
      '9.	Как узнать статус и результаты полученных услуг?',
      children : <NinthText/>
  },
  {
    titleText:
      '10.	Как связаться с поддержкой?',
      children : <NinthText/>
  },
  {
    titleText:
      '11.	Как начать переписку с исполнителем заявки по вопросу предоставленной услуги?',
      children : <EleventhText/>
  },
];

export default function MainFAQSection() {
  return (
    <>
      {/* f a q section */}
      <div className="tw-flex tw-flex-col tw-items-center">
        <div className="tw-mx-auto tw-max-w-[1282px] tw-w-[95%] md:tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-12 tw-px-5 md:tw-px-0">
          <p className="tw-text-[18px] tw-font-roboto tw-font-semibold tw-text-gray-900 md:tw-text-[26px] lg:tw-text-[28px]">
            Часто задаваемые вопросы
          </p>
          <div className="tw-grid lg:tw-grid-cols-2 tw-grid-rows-1 tw-gap-6 tw-self-stretch tw-items-start">
            <Suspense fallback={<div>Loading feed...</div>}>
              {faqGrid.map((d, index) => (
                <DigitalSignatureKeyInfo {...d}
                  titleText={
                    <p className="tw-mb-1.5 tw-font-inter tw-text-[14px] tw-font-normal tw-text-[#66727F]">
                      {d.titleText}
                    </p>
                  }
                  key={'faq' + index}
                  className="gap-5"
                />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
