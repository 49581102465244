import React from 'react';

interface Props {
  className?: string;
  socialImage?: string;
  socialTitle?: React.ReactNode;
  handleClick: () => void;
}

export default function SocialProtectionComponent({
  socialImage = '/images/img_simplification.svg',
  socialTitle = 'Социальная защита',
  handleClick,
  ...props
}: Props) {
  return (
    <div
      {...props}
      onClick={handleClick}
      className={`tw-flex tw-w-[32%] tw-flex-col tw-items-center tw-gap-4 tw-p-1 tw-rounded-xl hover:tw-cursor-pointer ${props.className} hover:tw-bg-[#48B7F4] tw-transition-all tw-ease-linear tw-duration-150`}
    >
      <img
        src={socialImage}
        alt="Социальная"
        className="tw-h-[56px] tw-w-[38%] tw-object-contain"
      />
      <p className="tw-self-stretch tw-overflow-hidden tw-overflow-ellipsis tw-text-center tw-text-[15px] tw-font-roboto tw-font-medium tw-leading-5 tw-text-white">
        {socialTitle}
      </p>
    </div>
  );
}
