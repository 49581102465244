import React from 'react';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  className?: string;
  documentTitle?: React.ReactNode;
  handleClick: () => void;
  automatic: boolean;
  mustPaid: boolean;
}

export default function DocumentSummary({
  documentTitle = 'Выдача выписок из лицевого счета о состоянии расчетов с бюджетом, а также по социальным платежам',
  handleClick,
  ...props
}: Props) {
  return (
    <div
      onClick={handleClick}
      {...props}
      className={`${props.className} tw-flex tw-justify-center tw-items-start tw-w-full tw-gap-7 tw-p-4 tw-bg-gray-50 tw-rounded-[12px] hover:tw-bg-[#C8E4FF] hover:tw-cursor-pointer tw-transition-all tw-ease-linear tw-duration-100`}
    >
      <img
        src="/images/img_iconoir_page_search_cyan_800.svg"
        alt="Image"
        className="tw-mt-[34px] tw-h-[32px]"
      />
      <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-1.5 tw-self-center">
        <p className="tw-overflow-hidden tw-overflow-ellipsis tw-text-[14px] tw-font-medium tw-leading-[18px] tw-text-[#66727F]">
          {documentTitle}
        </p>
        <div className="tw-flex tw-gap-4 tw-mt-4">
          {props.automatic ? (
            <Tooltip
              title="Автоматическая"
              sx={{
                fontSize: '1rem', // Увеличиваем размер шрифта
              }}
            >
              <img
                src="/images/img_mdi_automatic.svg"
                alt="Image"
                className="tw-h-[16px]"
              />
            </Tooltip>
          ) : (
            <Tooltip
              title="Полуавтоматическая"
              sx={{
                fontSize: '20px', // Увеличиваем размер шрифта
              }}
            >
              <img
                src="/images/img_bxs_circle_half.svg"
                alt="Image"
                className="tw-h-[16px]"
              />
            </Tooltip>
          )}

          {props.mustPaid ? (
            <Tooltip
              title="Платная услуга"
              sx={{
                fontSize: '1rem', // Увеличиваем размер шрифта
              }}
            >
              <img
                src="/images/img_fa_regular_money_bill_alt.svg"
                alt="Faregular"
                className="tw-h-[16px]"
              />
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
