import React from 'react';

const NinthText = () => {
  return (
    <div>
      <p>
        Для того чтобы узнать статус и результаты полученных, необходимо
        выполнить следующие шаги:
      </p>
      <p className="tw-mt-[10px]">
        Шаги для проверки статуса и результатов услуг:
      </p>
      <ul className="tw-list-decimal tw-pl-[30px]">
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Откройте вкладку «Документы и данные»:
          </span>
          После входа в личный кабинет перейдите в раздел "Документы и данные",
          который обычно находится в меню вашего профиля.
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Выберите вкладку «Приобретенные услуги»:
          </span>
          В разделе "Документы и данные" найдите и выберите вкладку
          "Приобретенные услуги". Это откроет реестр всех услуг, которые вы
          запросили через портал.
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Просмотр отправленных заявок и полученных документов: В реестре
            "Приобретенные услуги"
          </span>
          вы сможете увидеть:
          <ul className="tw-list-disc tw-pl-[30px]">
            <li className='tw-mt-[10px]'>
              Список всех{' '}
              <span className="tw-font-bold">отправленных заявок</span>{' '}
            </li>
            <li className='tw-mt-[10px]'>
              Статус каждой заявки (например, «Не оплачено», «Оплачено»,
              «Обработано», «Получено»).
            </li>
            <li className='tw-mt-[10px]'>
              <span className="tw-font-bold">Полученные документы</span>, такие
              как сканы или электронные версии документов, которые были
              предоставлены после обработки заявок.
            </li>
          </ul>
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Получение дополнительной информации о статусе:
          </span>
          Вы можете нажать на каждую заявку, чтобы узнать более подробную
          информацию о ее статусе или просмотреть сам документ
        </li>
      </ul>
    </div>
  );
};

export default NinthText;
