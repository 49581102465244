const baseAuthUrl = process.env.REACT_APP_BASE_AUTH_API_URL || '';

export const AuthApiRoutes = {
  /* AUTH */
  login: `${baseAuthUrl}/Api/Auth/Login`,
  logout: `${baseAuthUrl}/Api/Auth/logout`,
  refreshToken: `${baseAuthUrl}/Auth/refresh`,
  getCode: `${baseAuthUrl}/Api/Auth/getcode`,
  getCodeInResetPassword: `${baseAuthUrl}/Api/Auth/getcodeInResetPassword`,
  checkCode: `${baseAuthUrl}/Api/Auth/checkCode`,

  changePassword: `${baseAuthUrl}/api/User/changePassword`,
  resetPassword: `${baseAuthUrl}/api/User/resetPassword`,

  userProfileDetail: `${baseAuthUrl}/api/userprofile/userdetail`,

  /* USERS */
  createUser: `${baseAuthUrl}/api/User/create`,
  createLegal: `${baseAuthUrl}/api/User/createLegalEntity`,
  userUpdate: `${baseAuthUrl}/api/User/update`,
  updateLegal: `${baseAuthUrl}/api/User/updateLelagEntity`,
  getUser: `${baseAuthUrl}/api/User/get/`,
  getOrg: `${baseAuthUrl}/api/User/getOrg/`,

  changeEmail: `${baseAuthUrl}/api/User/changeEmail`,
  changeInn: `${baseAuthUrl}/api/User/changeINN`,
};

export enum ApiRoutes {
  /* APPLICATION RESIDENT */
  appResidentList = 'api/EgovApplicationResident/search',
  appResidentCreate = 'api/EgovApplicationResident/create',
  appResidentUpdate = 'api/EgovApplicationResident/update',
  appResidentSignDoc = 'api/EgovApplicationResident/signDocument',
  appResidentUndoDoc = 'api/EgovApplicationResident/undoDocument',
  appResidentAccept = 'api/EgovApplicationResident/accept',
  appResidentGet = 'api/EgovApplicationResident/get/',
  appResidentOpenDiscution = 'api/EgovApplicationResident/openDiscution',
  appResidentSendMessage = 'api/EgovApplicationResident/sendmessage',
  appResidentEditMessage = 'api/EgovApplicationResident/editmessage',
  appResidentCheckPayments = 'api/EgovApplicationResident/checkPayments',
  appResidentSendNotification = 'api/EgovApplicationResident/sendNotification',
  appResidentText = '/api/general/eGov_GetTextOfApplication/text',
  newMessage = 'api/EgovApplicationResident/newmessage',
  sendChatNotification = 'https://row-notification.azurewebsites.net/api/Notification/typing',

  /* SERVICES */
  getOrganisationsList = '/api/Service/egovlistOrganisation',
  getServiceListByOrgId = '/api/Service/egovlistService/',
  getServiceDetail = '/api/Service/get/',
  createService = '/api/Egov_Service_Requests/create',
  updateService = '/api/Egov_Service_Requests/update',
  signService = '/api/Egov_Service_Requests/sign',
  searchService = '/api/Egov_Service_Requests/search',
  getServiceById = '/api/Egov_Service_Requests/get/',
  getReceiptById = '/api/Egov_Service_Requests/Receipt/',
  getCategoryByOrgId = '/api/service/category/list/',
  getPopularServices = '/api/service/popularServices/list',

  //getReceiptWithInvoiceNumber = '/api/Egov_Service_Requests/ReceiptWithInvoiceNumber/',

  /* USER PROFILE */
  getAvailableUsers = '/api/userprofile/getAvailableUser',
  authorizeMe = '/api/userprofile/userprofile/authorizeMe',
  supportSendMessage = '/api/userprofile/create',
  profileDetail = '/api/userprofile/userdetail',
  changeEmail = '/api/userprofile/changeEmail',
  saveAvatar = '/api/userprofile/saveavatar',
  deleteAvatar = '/api/userprofile/deleteAvatar',
  userProfileSensMessageToAuthorized = '/api/userprofile/sensMessageToAuthorized',

  /* REQUISITES */
  createCards = '/api/UserCarts/create',
  getCards = '/api/UserCarts/search',
  deleteCards = 'api/UserCarts/delete',

  /*Documents */
  getDocuments = '/api/UserDocs/search',
  createDocuments = '/api/UserDocs/create',
  updateDocuments = '/api/UserDocs/update',

  /* GENERAL */
  generalRejectReasonsList = '/api/general/undoReason/list',
  generalYearList = '/api/general/year/get',
  generalCityList = '/api/general/city/list',
  generalDoctypeList = '/api/general/doctype/list',
  generalOrgTypeList = '/api/general/getOrganisationType',
  generalOrgList = '/api/general/eGov_Organizations',
  generalEgovGovernList = '/api/general/eGov_goverment/list',
  generalUserInnList = '/api/general/User_Inn_Informtion/list',
  generalEgovDoctypeList = '/api/general/eGov_Directory_type/list',
  generalEgovCountList = '/api/general/count/list',
  generalEgovContacts = '/api/general/eGov_Contacts',
  generalHotLinePhone = 'api/general/hotLinePhone/list',
  generalHotLineEmail = 'api/general/hotLineEmail/list',

  /* FILE SERVICE */
  uploadFileV2 = '/api/file/UploadFileV2',

  /* USER PROFILE */
  userProfileDetail = '/api/userprofile/userdetail',
  userProfileIsAuthorized = '/api/userprofile/IsAuthorized',
  userProfileUserSearch = '/api/userprofile/user/search',
  userProfileUserAuthorize = '/api/userprofile/user/authorize',
  userProfileGet = '/api/userprofile/get/',
  createCommentToService = "api/Service/comment/create",
  createRatingToService  = "api/Service/rating/create"
  
}
