import React, { useEffect } from 'react';
import UserProfile from 'components/newDesign/UserProfile';
import { RatingBar } from 'components/newDesign/RatingBar';
import Modal from 'react-modal';
import { useGetServiceDetailQuery } from 'store/hooks/services';
import DigitalSignatureKeyInfo from 'components/newDesign/DigitalSignatureKeyInfo';
import { downloadFileByUrl } from 'shared/utils/functions';
import { Button } from '@mui/material';
import { variants } from 'routes/public/service-details/helpers/constants';
import ProgressBar from 'components/newDesign/ProgressBar';
import { RatingI, CommentI } from 'store/hooks/services';
import { useLocation, useNavigate } from 'react-router-dom';
import UserAddComment from 'components/newDesign/UserAddComment';
import {
  useAddCommentToServiceMutation,
  useAddRatingToServiceMutation,
} from 'store/hooks/userprofile';
import { useFetchHotLinePhoneQuery } from 'store/hooks/general';
interface Props {
  className?: string;
  isOpen: boolean;
  serviceId: number | null;
  handleClose: () => void;
  getServiceClick: () => void;
}

export default function InfoModal({
  isOpen,
  handleClose,
  getServiceClick,
  serviceId,
  ...props
}: Props) {
  const { data: phone } = useFetchHotLinePhoneQuery('');
  const { data: details, refetch } = useGetServiceDetailQuery(serviceId || '');
  const [addCommentToService, { data, isLoading, reset, isSuccess }] =
    useAddCommentToServiceMutation();

  const [
    addRatingToService,
    {
      data: addRatingData,
      isLoading: addRatingLoad,
      reset: addRatingResest,
      isSuccess: addRatingSuccess,
    },
  ] = useAddRatingToServiceMutation();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      reset();
    }
    if (addRatingSuccess) {
      refetch();
      addRatingResest();
    }
  }, [isSuccess, addRatingSuccess]);

  return (
    <Modal
      {...props}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      className="tw-w-full tw-h-full tw-bg-[#000] tw-bg-opacity-40 tw-outline-none tw-z-40"
    >
      <div className="tw-max-w-[1200px] tw-w-full md:tw-w-[85%] tw-m-auto tw-z-[99999] tw-max-h-[calc(100vh-70px)] md:tw-max-h-[calc(100vh-20px)] tw-py-5 tw-overflow-y-auto tw-mt-24">
        <div className="tw-rounded-[24px] tw-bg-white">
          <div className="md:tw-p-10 tw-p-5">
            <div className="tw-mb-2 tw-flex tw-flex-col tw-gap-[22px]">
              <div className="tw-flex tw-items-start tw-justify-center tw-flex-col-reverse md:tw-flex-row">
                <div className="tw-mt-2 tw-flex tw-flex-1 tw-gap-5 md:tw-self-end tw-flex-col md:tw-flex-row tw-items-center tw-self-stretch">
                  <img
                    src="/images/img_iconoir_page_search_blue_gray_500_02_1.svg"
                    alt="Iconoirpage"
                    className="tw-hidden md:tw-block tw-h-[48px]"
                  />
                  <p className="md:tw-w-[58%] tw-overflow-hidden tw-overflow-ellipsis tw-text-[20px] tw-font-roboto tw-font-medium tw-leading-9 tw-text-[#66727F] tw-w-full">
                    {details?.name}
                  </p>
                </div>
                <button
                  className="tw-bg-transparent tw-border-none tw-cursor-pointer tw-self-end md:tw-self-start"
                  onClick={handleClose}
                >
                  <img
                    src="/images/img_arrow_right_gray_900_1.svg"
                    alt="Arrowright"
                    className="tw-h-[18px]"
                  />
                </button>
              </div>
              <div className="tw-flex tw-flex-row tw-gap-5 md:tw-gap-0">
                <button
                  onClick={() => {
                    if (pathname.includes('profile')) {
                      navigate(
                        `/services/details/${serviceId}/application/create`
                      );
                    } else {
                      getServiceClick();
                    }
                  }}
                  className="tw-flex tw-h-[40px] tw-min-w-[130px] md:tw-min-w-[200px] tw-flex-row tw-items-center tw-justify-center tw-rounded-lg tw-bg-cyan-800 md:tw-px-8 tw-text-center tw-text-[16px] tw-font-bold tw-text-white tw-px-5"
                >
                  Получить услугу
                </button>
                <button className="md:tw-ml-4 tw-flex tw-h-[40px] tw-w-[56px] md:tw-w-[72px] tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-cyan-800 tw-p-2 tw-ml-0">
                  <img src="/images/img_mdi_share_variant.svg" />
                </button>
                <button className="md:tw-ml-4 tw-flex tw-h-[40px] tw-w-[56px] md:tw-w-[72px]  tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-dashed tw-border-amber-400_01 tw-p-2 tw-ml-0">
                  <img src="/images/img_solar_lightbulb.svg" />
                </button>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-10 tw-bg-gray-100 md:tw-p-10 tw-p-5 tw-rounded-b-[24px]">
            <div className="tw-grid lg:tw-grid-cols-2 tw-grid-rows-1 tw-gap-8 tw-items-start">
              <DigitalSignatureKeyInfo
                className="tw-bg-white"
                imgSrc="/images/img_arrow_down_cyan_800.svg"
                iconRotate="tw-rotate-180"
                children={
                  <div className="tw-flex tw-flex-col tw-space-y-2 tw-items-start">
                    <p>
                      Цена услуги:{' '}
                      <span className="tw-font-semibold">{details?.price}</span>
                    </p>
                    <p>
                      Цена обработки заявления:{' '}
                      <span className="tw-font-semibold">
                        {details?.treatmentPrice}
                      </span>
                    </p>
                  </div>
                }
                titleText={
                  <div className="tw-flex tw-flex-1 tw-items-center tw-gap-5">
                    <img
                      src="/images/img_component_21.svg"
                      alt="Image"
                      className="tw-h-[24px]"
                    />
                    <p className="tw-font-inter tw-text-[15px] tw-font-semibold tw-text-[#66727F]">
                      Стоимость услуги
                    </p>
                  </div>
                }
              />

              <DigitalSignatureKeyInfo
                className="tw-bg-white"
                imgSrc="/images/img_arrow_down_cyan_800.svg"
                iconRotate="tw-rotate-180"
                children={
                  phone?.length > 0 &&
                  phone.map((item: string) => (
                    <a
                      className="tw-font-inter tw-text-[14px] tw-font-medium tw-tracking-[0.28px] tw-text-gray-900"
                      href={`tel:${item}`}
                      key={item}
                    >
                      {item}
                    </a>
                  ))
                }
                titleText={
                  <div className="tw-flex tw-flex-1 tw-items-center tw-gap-5">
                    <img
                      src="/images/img_phone.svg"
                      alt="Image"
                      className="tw-h-[24px]"
                    />
                    <p className="tw-font-inter tw-text-[15px] tw-font-semibold tw-leading-none tw-text-[#66727F]">
                      Контакты
                    </p>
                  </div>
                }
              />

              <DigitalSignatureKeyInfo
                className="tw-bg-white"
                imgSrc="/images/img_arrow_down_cyan_800.svg"
                iconRotate="tw-rotate-180"
                children={details?.term + ' ' + '- рабочих дней'}
                titleText={
                  <div className="tw-flex tw-flex-1 tw-items-center tw-gap-5">
                    <img
                      src="/images/img_time.svg"
                      alt="Image"
                      className="tw-h-[24px]"
                    />
                    <p className="tw-font-inter tw-text-[15px] tw-font-semibold tw-leading-none tw-text-[#66727F]">
                      Сроки оказания услуги
                    </p>
                  </div>
                }
              />

              <DigitalSignatureKeyInfo
                className="tw-bg-white"
                imgSrc="/images/img_arrow_down_cyan_800.svg"
                iconRotate="tw-rotate-180"
                children={<p>{details?.organisation?.value}</p>}
                titleText={
                  <div className="tw-flex tw-flex-1 tw-items-center tw-gap-5">
                    <img
                      src="/images/img_component_21_24x24.svg"
                      alt="Image"
                      className="tw-h-[24px]"
                    />
                    <p className="tw-font-inter tw-text-[15px] tw-font-semibold tw-leading-none tw-text-[#66727F]">
                      Организация
                    </p>
                  </div>
                }
              />

              <DigitalSignatureKeyInfo
                className="tw-bg-white"
                imgSrc="/images/img_arrow_down_cyan_800.svg"
                iconRotate="tw-rotate-180"
                children={
                  <ul>
                    {details?.files?.map((item) => (
                      <li key={item.id} className="tw-mb-3">
                        <span className="tw-inline-block tw-w-[12px] tw-h-[12px] tw-rounded-[6px] tw-bg-[#607D8B] tw-text-1.5xl tw-mr-4 tw-mt-[3px]"></span>
                        {item.name}
                        {item.template ? (
                          <Button
                            sx={{
                              fontSize: '12px',
                              textTransform: 'none',
                              ml: 2,
                            }}
                            onClick={() =>
                              downloadFileByUrl(item?.template || '')
                            }
                            variant="text"
                            size="small"
                          >
                            Скачать
                          </Button>
                        ) : (
                          ''
                        )}
                      </li>
                    ))}
                  </ul>
                }
                titleText={
                  <div className="tw-flex tw-flex-1 tw-items-center tw-gap-5">
                    <img
                      src="/images/img_doc.svg"
                      alt="Image"
                      className="tw-h-[24px]"
                    />
                    <p className="tw-font-inter tw-text-[15px] tw-font-semibold tw-leading-none tw-text-[#66727F]">
                      Документы необходимые для получения услуги
                    </p>
                  </div>
                }
              />

              <DigitalSignatureKeyInfo
                className="tw-bg-white"
                imgSrc="/images/img_arrow_down_cyan_800.svg"
                iconRotate="tw-rotate-180"
                children={
                  <ol style={{ marginBottom: '20px' }}>
                    {variants.map((item, idx) => (
                      <li key={item} className="tw-mb-3">
                        <span className="tw-text-1.5xl tw-mr-4">
                          {idx + 1}.{' '}
                        </span>
                        {item}
                      </li>
                    ))}
                  </ol>
                }
                titleText={
                  <div className="tw-flex tw-flex-1 tw-items-center tw-gap-5">
                    <img
                      src="/images/img_faq.svg"
                      alt="Image"
                      className="tw-h-[24px]"
                    />
                    <p className="tw-font-inter tw-text-[15px] tw-font-semibold tw-leading-none tw-text-[#66727F]">
                      Как получить услугу?
                    </p>
                  </div>
                }
              />
            </div>
            <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-7">
              <p className="tw-text-[24px] tw-font-normal tw-text-[#66727F] tw-md:text-[22px]">
                <span>Оценки и&nbsp;</span>
                <span>отзывы</span>
              </p>
              <div className="tw-flex tw-flex-col tw-gap-9 tw-self-stretch">
                <div className="tw-flex tw-items-start tw-gap-5 tw-flex-col md:tw-flex-row">
                  <div className="tw-flex md:tw-w-[14%] tw-flex-col tw-items-start tw-gap-4 tw-w-full">
                    <div className="tw-flex tw-flex-col tw-items-start tw-gap-1.5 tw-self-stretch">
                      <p className="lg:tw-text-[32px] tw-font-semibold tw-text-[#A6D3FF] md:tw-text-[30px] tw-text-[28px]">
                        {details?.averageRating}
                      </p>
                      <RatingBar
                        onChange={(value) => {
                          addRatingToService({
                            grade: value,
                            serviceId: serviceId,
                          });
                        }}
                        value={details?.averageRating}
                        isEditable={pathname.includes('profile')}
                        size={24}
                        className="tw-flex tw-gap-2.5"
                        activeColor="#A6D3FF"
                      />
                    </div>
                    <p className="tw-text-[12px] tw-font-normal tw-text-[#66727F]">
                      Оценили: {details?.appreciated}
                    </p>
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-gap-[26px]">
                  <p className="tw-text-[16px] tw-font-normal tw-text-[#66727F]">
                    Количество отзывов: {details?.comments?.length}
                  </p>
                  <div className="tw-flex tw-flex-col tw-space-y-6 tw-self-stretch tw-max-h-[240px] tw-overflow-y-auto">
                    {pathname.includes('profile') ? (
                      <UserAddComment
                        addCommentFunc={(value: string) => {
                          addCommentToService({
                            userComment: value,
                            id: serviceId,
                          });
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    {details?.comments?.map((el: CommentI, i: number) => {
                      return (
                        <UserProfile
                          key={`commment-${i + 1}`}
                          userImage={el.userPicture}
                          userName={el.userName}
                          userDescription={el.userComment}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
