import React from 'react';
import { useNavigate } from 'react-router-dom';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import { Slider } from './Slider';
import { useMediaQuery } from '@mui/material';

export default function GovernmentServicesSection({
  organs,
  path,
}: {
  organs: any;
  path: string;
}) {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef<AliceCarousel>(null);
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 560px)');

  return (
    <>
      {/* government services section */}
      <div className="tw-flex tw-flex-col md:tw-items-center tw-py-9">
        <div className="tw-mx-auto tw-max-w-[1282px] tw-w-[95%] md:tw-w-full tw-flex tw-flex-col tw-gap-[22px] tw-px-5 md:tw-px-0">
          <div className="tw-flex tw-items-start tw-justify-between tw-flex-col md:tw-flex-row">
            <p className="lg:tw-text-[28px] tw-font-roboto tw-font-semibold tw-text-gray-900 tw-md:text-[26px] tw-text-[18px] tw-mb-6 md:tw-mb-0">
              Услуги по государственным органам
            </p>
            {(isSmallDevice ? organs?.length > 1 : organs?.length > 3) && (
              <div className="tw-w-[95%] tw-flex tw-flex-1 tw-justify-end tw-gap-[18px] tw-self-stretch md:tw-self-auto">
                <button
                  onClick={() => {
                    sliderRef?.current?.slidePrev();
                  }}
                  className="tw-flex tw-h-[28px] tw-w-[28px] tw-items-center tw-justify-center tw-rounded-[14px] tw-bg-[#007B9E33] tw-px-1.5"
                >
                  <img
                    src="images/img_arrow_down_cyan_800_28x28.svg"
                    alt="toLeft"
                  />
                </button>
                <button
                  onClick={() => {
                    sliderRef?.current?.slideNext();
                  }}
                  className="tw-flex tw-h-[28px] tw-w-[28px] tw-rotate-[180deg] tw-items-center tw-justify-center tw-rounded-[14px] tw-bg-[#007B9E33] tw-px-1.5"
                >
                  <img
                    src="images/img_arrow_down_cyan_800_28x28.svg"
                    alt="toRight"
                  />
                </button>
              </div>
            )}
          </div>
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3">
            <Slider
              autoPlayInterval={2000}
              responsive={{
                '0': { items: 1 },
                '551': { items: 2 }, // На экранах с шириной больше 550px будет 2 элемента
                '1051': { items: 5 },
              }}
              disableDotsControls
              activeIndex={sliderState}
              onSlideChanged={(e: EventObject) => {
                setSliderState(e?.item);
              }}
              ref={sliderRef}
              items={organs?.map((el: any) => (
                <div
                  key={el.id}
                  onClick={() => {
                    navigate(`${path}/${el.id}`);
                  }}
                  className="tw-px-[11px] tw-h-[190px]"
                >
                  <div className="tw-w-full tw-h-full tw-mt-2 tw-flex tw-flex-col tw-items-center tw-gap-4 tw-rounded-[12px] tw-bg-gradient2 tw-px-5 tw-py-4 tw-opacity-75 hover:tw-opacity-100 hover:tw-translate-y-[-7px] hover:tw-cursor-pointer tw-transition-all tw-ease-linear tw-duration-100">
                    <img
                      src="images/img_simplification_gray_700_01.svg"
                      alt="Simplification"
                      className="tw-h-[72px] tw-w-[40%] tw-object-contain"
                    />
                    <p className="tw-mb-2 tw-self-stretch tw-overflow-hidden tw-overflow-ellipsis tw-text-center tw-text-[16px] tw-font-roboto tw-font-medium tw-leading-5 tw-text-[#000]">
                      {el.name}
                    </p>
                  </div>
                </div>
              ))}
            />
          </div>
        </div>
      </div>
    </>
  );
}
