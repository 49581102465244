import React from 'react'

const FourthTetx = () => {
  return (
    <div>
      <p className="">
          Для восстановления пароля необходимо выполнить несколько простых
          шагов. Если вы забыли свой пароль, можно сбросить его и создать новый,
          следуя инструкциям на странице восстановления пароля.
        </p>
        <p className="tw-mt-[10px]">Шаги для восстановления пароля:</p>
        <ul className="tw-list-decimal tw-pl-[30px]">
          <li className="tw-mt-[10px]">
            Перейдите на страницу восстановления пароля: На странице авторизации
            найдите и нажмите кнопку{' '}
            <span className="tw-font-bold">"Забыли пароль?"</span> . Это
            перенаправит вас на страницу, где вы сможете сбросить текущий пароль
            и создать новый.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Введите телефон:</span> <br />
            Введите номер мобильного телефона, который вы использовали при
            регистрации. Этот номер будет использован для отправки кода
            подтверждения.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Создайте новый пароль:</span> <br />
            Введите новый пароль, который вы хотите установить для своей учетной
            записи.
          </li>
          <li className="tw-mt-[10px]">
            <span className=" tw-font-bold">Подтвердите новый пароль:</span>{' '}
            <br />
            Повторите новый пароль для его подтверждения. Убедитесь, что оба
            введенных пароля совпадают.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Отправьте код:</span> <br />
            Нажмите кнопку <span className='tw-font-bold'>"Отправить код". </span> На указанный вами номер телефона
            будет отправлен код подтверждения.
          </li>
          <li className="tw-mt-[10px]">
            <span className="tw-font-bold">Введите код:</span> <br />
            Введите полученный код в соответствующее поле. Этот код служит для
            подтверждения вашей личности и для того, чтобы убедиться, что вы
            являетесь владельцем учетной записи.
          </li>
          <li className="tw-mt-[10px]">
            <span className='tw-font-bold'>
            Нажмите "Вход":
            </span> <br />
            После того как вы введете правильный код
            подтверждения и новый пароль, нажмите кнопку "Вход" для завершения
            восстановления пароля.
          </li>
        </ul>
    </div>
  )
}

export default FourthTetx
